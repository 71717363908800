<div class="link" [class.remote]="!local" [title]="plugin.config?.description || ''">
  <a [routerLink]="['/tag', qualifiedTag]">{{ plugin.name || plugin.tag }}</a>
  @if (plugin.name) {
    <span class="host">({{ qualifiedTag }})</span>
  } @else if (origin) {
    <span class="host">({{ origin }})</span>
  }
</div>
<div class="stack">
  <div class="info">
    <span [title]="plugin.modified?.toISO()" i18n>modified {{ plugin.modified?.toRelative() }}</span>
    @if (!local) {
      &nbsp;<span i18n>on</span>&nbsp;<a [routerLink]="['/tag', plugin.origin]">{{ plugin.origin }}</a>
    }
  </div>
  <div class="actions">
    @if (!created && store.account.admin) {
      <app-confirm-action #action [action]="copy$" i18n>install</app-confirm-action>
    } @else if (!local) {
      <app-inline-button #action [action]="copy$" i18n>copy</app-inline-button>
    }
    @if (created && local && store.account.admin) {
      <a class="fake-link" (click)="editing = !editing" i18n>edit</a>
    } @else {
      <a class="fake-link" (click)="viewSource = !viewSource" i18n>source</a>
    }
    <a class="fake-link" (click)="download()" i18n>download</a>
    @if (plugin.config?.export) {
      <a class="fake-link" (click)="export()" i18n>export</a>
    }
    @if (created && store.account.admin) {
      <app-confirm-action #action [action]="delete$" i18n>delete</app-confirm-action>
    }
  </div>
</div>

@if (editing) {
  <form class="form" [formGroup]="editForm" (ngSubmit)="save()">

    <app-plugin-form [group]="editForm"
                     [configErrors]="configErrors"
                     [defaultsErrors]="defaultsErrors"
                     [schemaErrors]="schemaErrors"></app-plugin-form>

    @for (e of serverError; track e) {
      <span><!-- Unexpected Error --></span>
      <div class="error">{{ e }}</div>
    }

    <span><!-- Buttons --></span>
    <span class="buttons right">
    <button type="submit" [disabled]="submitted && !editForm.valid" i18n>save</button>
    <button type="button" (click)="editing = false" i18n>cancel</button>
  </span>
  </form>
}  @else if (viewSource) {
  <form class="form" [formGroup]="editForm">
    <app-plugin-form [group]="editForm"></app-plugin-form>
  </form>
} @else {
  @for (e of serverError; track e) {
    <div class="error">{{ e }}</div>
  }
}
