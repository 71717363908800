<div class="link" [class.remote]="!local" [title]="template.config?.description || ''">
  <a *ngIf="template.tag" [routerLink]="['/tags', template.tag]">{{ template.name || template.tag }}</a>
  <a *ngIf="!template.tag" routerLink="/tags">{{ template.name || 'root' }}</a>
  <span *ngIf="!qualifiedTag" class="host" i18n>(root: "")</span>
  <span *ngIf="!template.tag && template.origin" class="host" i18n>(root: {{ template.origin }})</span>
  <span *ngIf="template.name && template.tag" class="host">({{ qualifiedTag }})</span>
  <span *ngIf="!template.tag && !template.name && template.origin" class="host">({{ template.origin }})</span>
</div>
<div class="stack">
  <div class="info">
    <span [title]="template.modified?.toISO()" i18n>modified {{ template.modified?.toRelative() }}</span>
    @if (!local) {
      &nbsp;<span i18n>on</span>&nbsp;<a [routerLink]="['/tag', template.origin]">{{ template.origin }}</a>
    }
  </div>
  <div class="actions">
    @if (!created && store.account.admin) {
      <app-confirm-action #action [action]="copy$" i18n>install</app-confirm-action>
    } @else if (!local) {
      <app-inline-button #action [action]="copy$" i18n>copy</app-inline-button>
    }
    @if (created && local && store.account.admin) {
      <a class="fake-link" (click)="editing = !editing" i18n>edit</a>
    } @else {
      <a class="fake-link" (click)="viewSource = !viewSource" i18n>source</a>
    }
    <a class="fake-link" (click)="download()" i18n>download</a>
    @if (created && store.account.admin) {
      <app-confirm-action #action [action]="delete$" i18n>delete</app-confirm-action>
    }
  </div>
</div>

@if (editing) {
  <form class="form" [formGroup]="editForm" (ngSubmit)="save()">

    <app-template-form [group]="editForm"
                       [configErrors]="configErrors"
                       [defaultsErrors]="defaultsErrors"
                       [schemaErrors]="schemaErrors"></app-template-form>

    @for (e of serverError; track e) {
      <span><!-- Unexpected Error --></span>
      <div class="error">{{ e }}</div>
    }

    <span><!-- Buttons --></span>
    <span class="buttons right">
    <button type="submit" [disabled]="submitted && !editForm.valid" i18n>save</button>
    <button type="button" (click)="editing = false" i18n>cancel</button>
  </span>
  </form>
} @else if (viewSource) {
  <form class="form" [formGroup]="editForm">
    <app-template-form [group]="editForm"></app-template-form>
  </form>
} @else {
  @for (e of serverError; track e) {
    <div class="error">{{ e }}</div>
  }
}
