<ng-container *mobxAutorun>
  <form class="form"
        [formGroup]="editForm"
        (ngSubmit)="save()"
        [appLimitWidth]="form?.fillPopover?.nativeElement || form?.fillSidebar?.nativeElement"
        [limitSibling]="true">
    <app-ext-form #form [group]="editForm"></app-ext-form>

    @for (e of serverError; track e) {
      <span><!-- Unexpected Error --></span>
      <div class="error">{{ e }}</div>
    }

    <span><!-- Buttons --></span>
    <span class="buttons right">
      @if (editing) {
        <app-loading [inline]="true"></app-loading>
      }
      <button type="submit" [disabled]="editing || submitted && !editForm.valid" i18n>Save</button>
    </span>
  </form>
</ng-container>
