<ng-container *mobxAutorun>
  <app-tabs>
    <a class="logo" routerLink="/"></a>
    <h5 class="fake-link" routerLink="." i18n>
      Submit
      @if (selectedPlugin) {
        <span>{{ selectedPlugin.name || selectedPlugin.tag }}</span>
      }
    </h5>
    <a routerLink="/submit"
       [class.current-tab]="store.submit.web && !store.submit.genId"
       [queryParams]="{ url: url.value, tag: store.submit.withoutGenId }"
       [replaceUrl]="true"
       i18n-title title="🔗️ Link Post"
       queryParamsHandling="merge" i18n>link</a>
    <a routerLink="/submit/text"
       [class.current-tab]="store.submit.text"
       [queryParams]="{ url: null, tag: store.submit.withoutGenId }"
       i18n-title title="📝️ Text Post"
       queryParamsHandling="merge" i18n>text</a>
    <a routerLink="/submit/upload"
       [class.current-tab]="store.submit.upload"
       [queryParams]="{ to: null, url: null }"
       i18n-title title="💾️ Upload"
       queryParamsHandling="merge" i18n>upload</a>
    @if (!admin.isWikiExternal()) {
      <a routerLink="/submit"
         [class.current-tab]="store.submit.wiki"
         [queryParams]="{ url: wikify, tag: store.submit.withoutGenId }"
         [replaceUrl]="true"
         i18n-title title="📔️ Wiki Page"
         queryParamsHandling="merge" i18n>wiki</a>
    }
    @if (admin.getTemplate('dm')) {
      <a routerLink="/submit/dm"
         [class.current-tab]="store.submit.subpage === 'dm' && !store.submit.dmPlugin"
         [queryParams]="{ to: null, tag: null, url: null }"
         i18n-title title="📨️ Direct Message"
         queryParamsHandling="merge" i18n>dm</a>
    }
    @for (p of store.submit.submitGenId; track p.tag) {
      @if (p.config?.submitDm) {
        <a routerLink="/submit/dm"
           [class.current-tab]="store.submit.subpage === 'dm' && store.submit.to[0] === p.tag"
           [queryParams]="{ to: p.tag, tag: null, url: null }"
           [replaceUrl]="true"
           [title]="p.name"
           queryParamsHandling="merge">{{ p.config!.submit }}</a>
      } @else if (p.config?.submitText) {
        <a routerLink="/submit/text"
           [class.current-tab]="store.submit.tags.includes(p.tag) && store.submit.url"
           [queryParams]="{ tag: submitInternal(p.tag), url: genUrl }"
           [replaceUrl]="true"
           [title]="p.name"
           queryParamsHandling="merge">{{ p.config!.submit }}</a>
      } @else {
        <a routerLink="/submit/web"
           [class.current-tab]="store.submit.tags.includes(p.tag) && store.submit.url"
           [queryParams]="{ tag: submitInternal(p.tag), url: genUrl }"
           [replaceUrl]="true"
           [title]="p.name"
           queryParamsHandling="merge">{{ p.config!.submit }}</a>
      }
    }
  </app-tabs>
  <router-outlet></router-outlet>

  @if (!store.submit.subpage) {
    <div class="full-page-form">
      <form class="form" [formGroup]="submitForm" (ngSubmit)="submit()">
        @if (!store.submit.wiki && selectedPlugin) {
          <span i18n>Type:</span>
          <div>
            <span>{{ selectedPlugin.name || selectedPlugin.tag }}</span>
            @if (selectedPlugin.config?.description) {
              <p class="description">
                {{ selectedPlugin.config!.description }}
              </p>
            }
          </div>
        }
        @if (store.submit.wiki) {
          <span i18n>Type:</span>
          <div>
            <span i18n>Wiki</span>
            <p class="description" i18n>
              Create a text post that can be linked to via double bracket [[Wiki]] style.
            </p>
          </div>
        }

        @if (store.submit.wiki) {
          <label for="url" class="big">Title:</label>
        } @else {
          <label for="url" class="big"></label>
        }
        <div class="form-array">
          @if (store.submit.web) {
            <app-select-plugin [(plugin)]="plugin"
                               [settings]="!!admin.getPluginSettings(store.submit.tags).length"></app-select-plugin>
          }
          @if (uploading) {
            <progress class="grow" max="100" [value]="progress"></progress>
          } @else {
            <input id="url"
                   [placeholder]="placeholder"
                   class="big"
                   type="text"
                   size="1"
                   autocomplete="off"
                   formControlName="url"
                   appAutofocus>
          }
          @if (!store.submit.wiki) {
            @if ((!plugin || plugin === 'plugin/qr') && admin.getPlugin('plugin/qr')) {
              <app-qr-scanner [upload]="plugin === 'plugin/qr'" (data)="scanQr($event)"></app-qr-scanner>
            }
            @if (admin.getPlugin('plugin/file')) {
              @switch (plugin) {
                @case (  'plugin/pdf') { <app-pdf-upload   (data)="onUpload($event)"></app-pdf-upload> }
                @case ('plugin/audio') { <app-audio-upload (data)="onUpload($event)"></app-audio-upload> }
                @case ('plugin/video') { <app-video-upload (data)="onUpload($event)"></app-video-upload> }
                @case ('plugin/image') { <app-image-upload (data)="onUpload($event)"></app-image-upload> }
              }
            }
          }
        </div>

        @for (e of serverErrors; track e) {
          <span><!-- Unexpected Error --></span>
          <div class="error">{{ e }}</div>
        }

        <span><!-- Tags --></span>
        <div class="form-array right">
          @for (tag of store.submit.tags | plugins; track tag) {
            <span>{{ tag }}</span>
          }
        </div>

        @if (!uploading) {
          @if (existingRef) {
            <br>
            <app-ref [ref]="existingRef" [showToggle]="true"></app-ref>
          }

          <br>
          <div class="validations">
            @for (v of validations; track v.name) {
              @if (v.passed) {
                <div>✅️ &nbsp;{{ v.name }}</div>
              } @else {
                <div>❌️ &nbsp;{{ v.name }}</div>
              }
            }
          </div>

          <span><!-- Buttons --></span>
          <span class="buttons right">
          <button type="submit" [disabled]="!submitForm.valid && !existingRef"
                  i18n-title title="Submit link"
                  i18n>{{ repost ? 'Repost' : 'Next' }}</button>
        </span>
        }
      </form>

      <span></span>
      <div class="summary-box" [class.hidden-without-removing]="!store.submit.refs.length">
        <h5 routerLink="./responses" i18n>Unsaved:</h5>
        <div class="list-container">
          @for (ref of store.submit.topRefs; track ref.url; let i = $index) {
            <div class="list-number">{{ i + 1 }}</div>
            <app-ref [ref]="ref" [showToggle]="true"></app-ref>
          }
        </div>
        @if (store.submit.refs.length > store.submit.topRefs.length) {
          <a routerLink="./submit/upload"
             queryParamsHandling="merge"
             class="load-more no-select" i18n>
            more unsaved
          </a>
        }
      </div>
    </div>
  }
</ng-container>
