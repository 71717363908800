<div class="list-container"
     [style.grid-template-columns]="colStyle">
  @for (ref of pinned; track ref.origin + '@' + ref.url) {
    <app-note class="pinned ref-list-item"
              [ref]="ref"
              [ext]="ext"></app-note>
  }
  @for (ref of newRefs; track ref.origin + '@' + ref.url) {
    <app-note class="ref-list-item"
              [ref]="ref"
              [ext]="ext"></app-note>
  }
  @for (ref of page?.content; track ref.origin + '@' + ref.url) {
    @if (!hide?.includes($any(ref.modified?.valueOf()) || 0)) {
      <app-note class="ref-list-item"
                [ref]="ref"
                [ext]="ext"></app-note>
    }
  }
</div>
@if (page) {
  @if (!page.content.length) {
    @if (!newRefs.length && emptyMessage) {
      <p class="no-results">{{ emptyMessage }}</p>
    }
  } @else {
    <app-page-controls *ngIf="pageControls"
                       [showPageLast]="showPageLast"
                       [page]="page"
                       [defaultCols]="cols"
                       [showPrev]="showPrev"></app-page-controls>
  }
} @else {
  <app-loading></app-loading>
}
