<select #create
        class="big"
        (input)="addFilter($any(create.value))"
        i18n-title title="Filter">
  <option class="unselected" i18n>{{ emoji }} filter</option>
  @for (g of allFilters; track g.label) {
    @if (g.filters.length) {
      <optgroup [label]="g.label">
        @for (f of g.filters; track f.filter) {
          <option [value]="f.filter" [title]="f.title || ''">{{ f.label || f.filter }}</option>
        }
      </optgroup>
    }
  }
</select>
@for (filter of filters; track filter; let i = $index) {
  <div class="controls" [title]="filter">
    <select id="filter-{{ i }}" [ngModel]="filter" (ngModelChange)="setFilter(i, $event)" [title]="title(filter)">
      @for (g of allFilters; track g.label) {
        @if (g.filters.length) {
          <optgroup [label]="g.label">
            @for (f of g.filters; track f.filter) {
              <option [value]="f.filter" [title]="f.title || ''">{{ f.label || f.filter }}</option>
            }
          </optgroup>
        }
      }
    </select>
    @if (negatable(filter)) {
      <button type="button" (click)="toggleQuery(i)">{{ store.account.querySymbol('!') }}</button>
    }
    <button type="button" (click)="removeFilter(i)" i18n>&ndash;</button>
  </div>
  @if (filter.startsWith('modified/')) {
    <input type="datetime-local"
           step="60"
           [ngModel]="toDate(filter)"
           (input)="!$any($event.target).isFocused() && $any($event.target).focus()"
           (keydown)="$event.key === 'Enter' && setModified(i, filter.startsWith('modified/before/'), toIso($any($event.target).value)) || true"
           (blur)="setModified(i, filter.startsWith('modified/before/'), toIso($any($event.target).value))">
  } @else if (filter.startsWith('response/')) {
    <input type="datetime-local"
           step="60"
           [ngModel]="toDate(filter)"
           (input)="!$any($event.target).isFocused() && $any($event.target).focus()"
           (keydown)="$event.key === 'Enter' && setModified(i, filter.startsWith('response/before/'), toIso($any($event.target).value)) || true"
           (blur)="setResponse(i, filter.startsWith('response/before/'), toIso($any($event.target).value))">
  } @else if (filter.startsWith('published/')) {
    <input type="datetime-local"
           step="60"
           [ngModel]="toDate(filter)"
           (input)="!$any($event.target).isFocused() && $any($event.target).focus()"
           (keydown)="$event.key === 'Enter' && setModified(i, filter.startsWith('published/before/'), toIso($any($event.target).value)) || true"
           (blur)="setPublished(i, filter.startsWith('published/before/'), toIso($any($event.target).value))">
  } @else if (filter.startsWith('created/')) {
    <input type="datetime-local"
           step="60"
           [ngModel]="toDate(filter)"
           (input)="!$any($event.target).isFocused() && $any($event.target).focus()"
           (keydown)="$event.key === 'Enter' && setModified(i, filter.startsWith('created/before/'), toIso($any($event.target).value)) || true"
           (blur)="setCreated(i, filter.startsWith('created/before/'), toIso($any($event.target).value))">
  }
}
