import { DateTime } from 'luxon';
import { Mod } from '../model/tag';
import { Template } from '../model/template';

export const homeTemplate: Template = {
  tag: 'home',
  name: $localize`🏡️ Home Page`,
  config: {
    type: 'config',
    default: true,
    generated: $localize`Generated by jasper-ui ${DateTime.now().toISO()}`,
    description: $localize`Customise this Ext to customise the home screen.`,
  },
  defaults: {
    sidebar: $localize`[🏷️ Subscriptions](/tags?home)`,
  }
};

export const homeMod: Mod = {
  template: [
    homeTemplate,
  ]
};
