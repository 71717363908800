<form>
  <app-editor [control]="comment"
              [tags]="tags"
              [selectResponseType]="selectResponseType"
              (syncTags)="editorTags = $event"
              [autoFocus]="autofocus"></app-editor>
</form>
@for (e of serverError; track e) {
  <div class="error">{{ e }}</div>
}

<button type="button" [disabled]="replying" (click)="reply()" i18n>reply</button>
@if (replying) {
  <app-loading [inline]="true"></app-loading>
} @else if (showCancel) {
  <button type="button" (click)="cancel()" i18n>cancel</button>
}
