<ng-container [formGroup]="group"
              cdkDropListGroup>
  <label for="url" i18n>URL:</label>
  <div class="form-array">
    <input id="url" type="text" formControlName="url">
    <button type="button" (click)="alts.addLink('')" i18n-title title="+ Add another alternate URL" i18n>📎️</button>
  </div>
  <app-links #alts
             fieldName="alternateUrls"
             i18n-label label="Alts:"
             [showLabel]="dropping || !!alts.links?.length"
             i18n-emoji emoji="📎️"
             [showAdd]="false"
             [group]="group"
             [class.no-margin]="!dropping && !alts.links?.length"></app-links>

  <label for="title" i18n>Title:</label>
  <div class="form-array">
    <input id="title"
           type="text"
           [formControl]="title"
           (blur)="validate($any($event.target))">
    @if (web) {
      <button type="button" (click)="scrapeTitle()" i18n-title title="Scrape Title" i18n>🪝</button>
    }
  </div>

  <label for="published" i18n>Published:</label>
  <div class="form-array">
    <input id="published"
           type="datetime-local"
           step="1"
           [formControl]="published"
           (blur)="validate($any($event.target))">
    @if (web) {
      <button type="button" (click)="scrapePublished()" i18n-title title="Scrape Published" i18n>🪝</button>
    }
    <button type="button" (click)="sources.addLink('')" i18n-title title="+ Add another source" i18n>📜️</button>
  </div>
  <span><!-- Published Errors --></span>
  <div>
    @if (published.touched && published.errors?.['required']) {
      <div class="error" i18n>
        Published date is required.
      </div>
    }
  </div>
  <app-links #sources
             id="sources"
             fieldName="sources"
             i18n-label label="Sources:"
             [showLabel]="dropping || !!sources.links?.length"
             i18n-emoji emoji="🔗️"
             [showAdd]="false"
             [group]="group"
             [class.no-margin]="!dropping && !sources.links?.length"></app-links>

  <div class="add-plugins-label">
    <label for="plugins" class="mobile-inline" i18n>Add Plugin:</label>
    <app-select-plugin #pluginSelect
                       class="shrink"
                       [add]="true"
                       i18n-title title="Add Plugin"
                       (pluginChange)="togglePlugin($event); pluginSelect.plugin = ''"></app-select-plugin>
  </div>
  <app-form-plugins id="plugins"
                    (togglePlugin)="togglePlugin($event)"
                    [tags]="$any(group.value).tags"
                    [group]="group"></app-form-plugins>

  @if (tags.editingViewer) {
    <span><!-- Viewer --></span>
    <app-viewer [text]="ref?.comment"
                [tags]="$any(group.value).tags"
                [commentControl]="comment"
                (comment)="comment.setValue($event)"></app-viewer>
  } @else {
    <label for="comment" class="editor-label">{{ editorLabel }}:</label><!-- TODO: Let plugins change comment label -->
    <div #fill class="fill-editor">
      <app-editor id="comment"
                  class="bubble"
                  [addButton]="true"
                  [addCommentTitle]="addEditorTitle"
                  [addCommentLabel]="addEditorLabel"
                  [control]="comment"
                  [tags]="$any(group.value).tags"
                  [fillWidth]="fill"
                  [url]="web && url.value"
                  (scrape)="scrapeAll()"
                  (syncEditor)="syncEditor()"
                  (syncTags)="editorTags = $event"></app-editor>
    </div>
  }

  <app-tags #tags [group]="group" [origin]="origin"></app-tags>

</ng-container>
