import { DateTime } from 'luxon';
import { Plugin } from '../model/plugin';
import { Mod, modSchema } from '../model/tag';

export const modPlugin: Plugin = {
  tag: 'plugin/mod',
  name: $localize`🎁️ Mod`,
  config: {
    default: true,
    embeddable: true,
    generated: $localize`Generated by jasper-ui ${DateTime.now().toISO()}`,
    description: $localize`Install a mod.`,
    aiInstructions: `# plugin/mod
    The plugin/mod plugin holds mods in a Ref to be installed by an admin.`,
    icons: [{ label: $localize`🎁️`, order: 2 }],
    filters: [
      { query: 'plugin/mod', label: $localize`🎁️ mod`, title: $localize`Mod`, group: $localize`Plugins 🧰️` },
    ],
    actions: [{ event: 'install', label: $localize`install`, title: $localize`Install this mod`, confirm: $localize`Are you sure you want to install this mod?` }],
  },
  schema: modSchema,
};

export const modMod: Mod = {
  plugin: [
    modPlugin,
  ]
};
