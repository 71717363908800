@if (icons.length) {
  <div>
    @for(icon of icons; track icon) {
      <span class="icon"
            [appTitle]="icon"
            [ref]="group.value">{{ icon.label || icon.thumbnail }}</span>
    }
  </div>
}
@for (plugin of forms; track plugin.tag) {
  @if (!plugin.config?.submitChild) {
    <app-form-gen #gen
                  [plugins]="plugins"
                  [plugin]="plugin"
                  (togglePlugin)="togglePlugin.next($event)"
                  [children]="admin.getPluginSubForms(plugin.tag)"></app-form-gen>
  }
}
@if (empty) {
  <div class="empty-plugins">
    no plugins
  </div>
}
