<ng-container *mobxAutorun>
  @if (collapsed) {
    <div class="comment-container info collapsed" tabindex="0">
      <span class="comment-expand fake-link" (click)="store.local.setRefToggled('comment:' + ref.url, !(collapsed = false))" i18n>[+]</span>&nbsp;
      @if (authors.length) {
        @if (authorExts$ | async; as authorExts) {
          @for (user of authorExts; track user.tag + user.origin) {
            <a class="user tag"
               [title]="formatAuthor(user.tag + user.origin)"
               [routerLink]="['/tag', user.tag + user.origin]"
               [appTitle]="user">{{ user.name || formatAuthor(user.tag + user.origin) }}</a>
          }
        } @else {
          @for (t of authors; track t) {
            <a class="user tag"
               [title]="formatAuthor(t)"
               [routerLink]="['/tag', t]">{{ formatAuthor(t) }}</a>
          }
        }
      }
      @if (store.view.isVoteSorted && admin.getPlugin('plugin/vote/up')) {
        <span class="vote-score" i18n>{{ score }} points&nbsp;</span>
      }
      <span [title]="ref.created?.toISO()">{{ ref.created?.toRelative() }}</span>
      @if (!modifiedIsSubmitted) {
        <span [title]="ref.modified!.toRelative()">* </span>
      }
      @for (icon of icons; track icon) {
        @if (showIcon(icon)) {
          <span class="icon"
                [class.filter-toggle]="icon.tag"
                (click)="clickIcon(icon, store.hotkey)"
                [appTitle]="icon"
                [ref]="ref">{{ icon.label }}</span>
        }
      }
      ({comments, plural, =1 {1&thinsp;child} other {{{ comments }}&thinsp;children}})
    </div>
  }

  @if (deleted && (comments || responses)) {
    <div class="comment-container info collapsed" tabindex="0" i18n>
      deleted comment
    </div>
  }
  <div class="row">
    @if (store.view.isVoteSorted && !collapsed && !deleted && store.account.user && admin.getPlugin('plugin/vote/up')) {
      <div class="voting">
        <div class="vote-up"
             [class.on]="upvote"
             (click)="voteUp()"></div>
        @if (admin.getPlugin('plugin/vote/down')) {
          <div class="vote-down"
               [class.on]="downvote"
               (click)="voteDown()"></div>
        }
      </div>
    }
    @if (!collapsed && !deleted) {
      <div class="comment-container" tabindex="0">
        <div class="info">
          <span class="comment-collapse fake-link" (click)="store.local.setRefToggled('comment:' + ref.url, !(collapsed = true))">[&ndash;]</span>&nbsp;
          @if (authors.length) {
            @if (authorExts$ | async; as authorExts) {
              @for (user of authorExts; track user.tag + user.origin) {
                <a class="user tag"
                   [title]="formatAuthor(user.tag + user.origin)"
                   [routerLink]="['/tag', user.tag + user.origin]"
                   [appTitle]="user">{{ user.name || formatAuthor(user.tag + user.origin) }}</a>
              }
            } @else {
              @for (t of authors; track t) {
                <a class="user tag"
                   [title]="formatAuthor(t)"
                   [routerLink]="['/tag', t]">{{ formatAuthor(t) }}</a>
              }
            }
          }
          @if (admin.getPlugin('plugin/vote/up')) {
            <span class="vote-score" i18n>{{ score }} points&nbsp;</span>
          }
          <span [title]="ref.created?.toISO()">{{ ref.created?.toRelative() }}</span>
          @if (!modifiedIsSubmitted) {
            <span [title]="'last edited ' + ref.modified!.toRelative()">* </span>
          }
          @if (tagged.length) {
            <span i18n> tagged</span>
            @if (tagExts$ | async; as tagExts) {
              @for (e of tagExts; track e.tag) {
                <a class="tag"
                   [routerLink]="['/tag', e.tag]"
                   [appTitle]="e.tag">{{ e.name || e.tag }}</a>
              }
            } @else {
              @for (t of tagged; track t) {
                <a class="tag" [routerLink]="['/tag', t]">{{ t }}</a>
              }
            }
          }
          @for (icon of icons; track icon) {
            @if (showIcon(icon)) {
              <span class="icon"
                    [class.filter-toggle]="icon.tag"
                    (click)="clickIcon(icon, store.hotkey)"
                    [appTitle]="icon"
                    [ref]="ref">{{ icon.label }}</span>
            }
          }
        </div>
        @if (editing) {
          <app-comment-edit [ref]="ref"
                            [commentEdited$]="commentEdited$"></app-comment-edit>
        } @else {
          <app-viewer class="comment-body"
                      [expand]="false"
                      [ref]="ref"></app-viewer>
        }
        <div class="actions">
          <a [routerLink]="['/ref', ref.url, 'comments']"
             [queryParams]="{ origin: nonLocalOrigin }"
             (click)="store.view.preloadRef(ref)"
             i18n>permalink</a>
          <a class="fake-link"
             (click)="replying = !replying"
             i18n>reply</a>
          @if(responses) {
            <a [routerLink]="['/ref', ref.url, 'responses']"
               [queryParams]="{ origin: nonLocalOrigin }"
               (click)="store.view.preloadRef(ref)"
               i18n>{responses, plural, =1 {1&thinsp;citation} other {{{ responses }}&thinsp;citations}}</a>
          }
          @if (sources > 2) {
            <a [routerLink]="['/ref', ref.url, 'sources']"
               [queryParams]="{ origin: nonLocalOrigin }"
               (click)="store.view.preloadRef(ref)"
               i18n>{{ sources }}&thinsp;sources</a>
          }
          @if (writeAccess) {
            <a class="fake-link"
               (click)="editing = !editing" i18n>edit</a>
          }
          @if (store.account.mod || taggingAccess) {
            <app-confirm-action #action
                                [class.force-delete]="deleteAccess"
                                [action]="store.hotkey ? forceDelete$ : delete$" i18n>delete</app-confirm-action>
          }
          @if (taggingAccess) {
            <app-inline-tag #action
                            [action]="tag$"
                            i18n>tag</app-inline-tag>
          }
          @if (canInvoice) {
            <a [routerLink]="['/submit/invoice']"
               [queryParams]="{ url: ref.url }"
               i18n>invoice</a>
          }
          <app-action-list [ref]="ref"
                           [showDownload]="false"
                           [groupedActions]="groupedActions"></app-action-list>
        </div>
      </div>
    }
  </div>
  @if (replying) {
    <app-comment-reply [autofocus]="true"
                       [to]="ref"
                       [showCancel]="true"
                       [tags]="replyTags"
                       (save)="newComments$.next($event)"></app-comment-reply>
  }
  <div class="comment-children"
       [class.hidden-without-removing]="collapsed">
    <app-comment-thread [source]="ref"
                        [scrollToLatest]="scrollToLatest"
                        [depth]="depth! - 1"
                        [context]="context + 1"
                        [newComments$]="newComments$"></app-comment-thread>
  </div>
  @if (!collapsed && ((depth === 1 && comments) || moreComments)) {
    @if (context < maxContext) {
      <span class="fake-link load-more no-select"
            (click)="loadMore()" i18n>
        load more comments
      </span>
    } @else {
      <a class="load-more"
         [routerLink]="['/ref', ref.url, 'comments']"
         [queryParams]="{ origin: nonLocalOrigin }"
         (click)="store.view.preloadRef(ref)" i18n>
        continue this thread
      </a>
    }
  }

  @for (e of serverError; track e) {
    <div class="error">{{ e }}</div>
  }
</ng-container>
