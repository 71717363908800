import { DateTime } from 'luxon';
import { Plugin } from '../model/plugin';
import { Mod } from '../model/tag';
import { Template } from '../model/template';

export const notesTemplate: Template = {
  tag: 'notes',
  name: $localize` Notebook`,
  config: {
    type: 'lens',
    mod: $localize`✍️ Notes`,
    experimental: true,
    generated: $localize`Generated by jasper-ui ${DateTime.now().toISO()}`,
    view: $localize`📓️`,
    description: $localize`Keep private notes in a notebook.`,
    icons: [
      { label: $localize`✍️` },
    ],
    filters: [
      { query: 'notes', label: $localize`✍️ notes`, title: $localize`Notes`, group: $localize`Templates 🎨️` },
    ],
    // language=CSS
    css: `
      app-ref-list.notes {
        .list-container {
          grid-auto-flow: row dense;
          padding: 4px;
          gap: 8px;
          grid-template-columns:  1fr;
          @media (min-width: 1000px) {
            grid-template-columns:  1fr 1fr;
          }
          @media (min-width: 1500px) {
            grid-template-columns: 1fr 1fr 1fr;
          }
          @media (min-width: 2000px) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
          }
          .list-number {
            display: none;
          }
          .ref {
            break-inside: avoid;
            .toggle {
              display: none;
            }
            @media (max-width: 740px) {
              .actions, .info {
                height: 28px;
              }
            }
          }
        }
      }
    `,
    form: [{
      key: 'badges',
      className: 'badges',
      type: 'tags',
      props: {
        label: $localize`Badges:`,
        addText: $localize`+ Add another badge tag`,
      }
    }]
  },
  defaults: {
    defaultCols: 0, // Leave to CSS screen size detection, but show cols dropdown
    badges: ['reminder', 'important']
  },
  schema: {
    optionalProperties: {
      badges: { elements: { type: 'string' } },
    },
  }
};

export const notesMod: Mod = {
  template: [
    notesTemplate,
  ]
};
