@if (page) {
  <div class="list-container">
    @for (t of page.content; track t.tag + t.origin; let i = $index) {
      <div class="list-number">{{ i + page.page.number * page.page.size + 1 }}</div>
      <app-template [template]="t"></app-template>
    }
  </div>
  @if (page.content.length) {
    <app-page-controls [page]="page"></app-page-controls>
  } @else {
    <p class="no-results" i18n>No results found</p>
  }
} @else {
  <app-loading></app-loading>
}
