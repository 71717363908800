@if (iconEmoji || iconColor) {
  <div class="file-icon thumbnail"
       [style.background-image]="[repostRef, ref] | thumbnail | async | cssUrl"
       [style.background-color]="iconColor"
       [style.border-radius.px]="iconRadius">{{ iconEmoji }}</div>
} @else if (thumbnail) {
  <div class="file-icon thumbnail"
       [style.background-image]="[repostRef, ref] | thumbnail: true | async | cssUrl"
       [style.border-radius.px]="iconRadius">{{ iconEmoji }}</div>
} @else if (iconEmojiDefaults) {
  <div class="file-icon thumbnail">{{ iconEmojiDefaults }}</div>
} @else {
  <div class="file-icon thumbnail" [class.default-thumbnail]="true"></div>
}
<div class="row">
  <div class="toggle"
       *ngIf="showToggle && (ref.comment || expandPlugins.length)"
       (click)="editing ? editing = false : viewSource = false">
    <span class="toggle-plus" *ngIf="!editing && !viewSource && !expanded">＋</span>
    <span class="toggle-x" *ngIf="editing || viewSource || expanded">✕</span>
  </div>
  <a [routerLink]="['/ref', ref.url]"
     [queryParams]="{ origin: nonLocalOrigin }"
     (click)="saveRef()"
     [style.pointer-events]="dragging ? 'none' : undefined">{{ title }}</a>
</div>

<app-viewer *ngIf="expanded" [ref]="ref"></app-viewer>
