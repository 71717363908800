@if (query.responseOf) {
  <div class="list-container">
    <div class="label" i18n>Responses Of:</div>
    <app-ref class="full-page"
             [ref]="query.responseOf"
             [showToggle]="true"
             (copied)="query.refresh()"></app-ref>
  </div>
}
@if (query.sourcesOf) {
  <div class="list-container">
    <div class="label" i18n>Sources Of:</div>
    <app-ref class="full-page"
             [ref]="query.sourcesOf"
             [showToggle]="true"
             (copied)="query.refresh()"></app-ref>
  </div>
}
@if (isTemplate('graph')) {
  <app-force-directed #lens
                      [tag]="tag"
                      [content]="page?.content || []"></app-force-directed>
} @else if (isTemplate('notes')) {
  <app-notebook #lens
                [class]="cssClass(ext?.tag)"
                [ext]="ext"
                [page]="page"
                [pageControls]="pageControls"
                [plugins]="plugins"
                [cols]="cols"></app-notebook>
} @else if (isTemplate('kanban')) {
  <app-kanban #lens
              [query]="tag"
              [ext]="ext!"
              [fullPage]="fullPage"
              [pageControls]="pageControls"
              [size]="size"
              [sort]="sort"
              [filter]="filter"
              [search]="search"></app-kanban>
} @else if (isTemplate('blog')) {
  <app-blog #lens
            [ext]="ext!"
            [page]="page"
            [pageControls]="pageControls"
            [cols]="cols"></app-blog>
} @else if (isTemplate('chat')) {
  <app-chat #lens
            [query]="tag"></app-chat>
} @else if (isTemplate('folder')) {
  <app-folder #lens
              [page]="page"
              [tag]="tag"
              [ext]="ext!"></app-folder>
} @else {
  <app-ref-list #lens
                [class]="cssClass(ext?.tag)"
                [ext]="ext"
                [page]="page"
                [pageControls]="pageControls"
                [plugins]="plugins"
                [cols]="cols"
                [showAlarm]="showAlarm"
                [showVotes]="showVotes"></app-ref-list>
}
