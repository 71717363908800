<span class="form-array">
  <div class="grow">
    <input [type]="type"
           autocorrect="off"
           autocapitalize="none"
           (keydown)="keydown($event)"
           [placeholder]="placeholder"
           [(ngModel)]="addingText">
  </div>
  <button type="button" (click)="add()" i18n>+</button>
</span>
<div class="error" *ngIf="error">{{ error }}</div>
<span class="form-array" *ngFor="let l of list; let i = index">
  <span tabindex="0"
        [class.selected]="selectedIndex === i"
        (focus)="select(i)">{{ l }}</span>
  <button type="button" (click)="remove(i)" i18n>&ndash;</button>
</span>
